import React, { createContext } from "react";
import { useStaticQuery, graphql } from "gatsby";

const ArticlesContext = createContext({});
function ArticlesProvider({ children }) {
  const {
    allContentfulPosts: { posts },
  } = useStaticQuery(graphql`
    {
      allContentfulPosts(limit: 6, sort: { fields: date, order: DESC }) {
        posts: nodes {
          title
          video
          url
          image {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1, width: 110)
          }
          id
          description
          date(formatString: "MMM DD, YYYY")
          tags {
            id
            name
            url
          }
        }
      }
    }
  `);

  return (
    <ArticlesContext.Provider value={{ posts }}>
      {children}
    </ArticlesContext.Provider>
  );
}

export { ArticlesContext, ArticlesProvider };
