exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-creator-js": () => import("./../../../src/pages/creator.js" /* webpackChunkName: "component---src-pages-creator-js" */),
  "component---src-pages-discuss-js": () => import("./../../../src/pages/discuss.js" /* webpackChunkName: "component---src-pages-discuss-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-hoc-javascript-co-ban-jsx": () => import("./../../../src/pages/hoc-javascript-co-ban.jsx" /* webpackChunkName: "component---src-pages-hoc-javascript-co-ban-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-javascript-projects-for-beginners-js": () => import("./../../../src/pages/javascript-projects-for-beginners.js" /* webpackChunkName: "component---src-pages-javascript-projects-for-beginners-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-blog-blog-post-js": () => import("./../../../src/templates/blog/blog-post.js" /* webpackChunkName: "component---src-templates-blog-blog-post-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */)
}

